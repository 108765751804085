import React, { useState, useEffect } from 'react'
import Tridi from 'react-tridi';
import 'react-tridi/dist/index.css';
import Marker from "./marker_s";
import { track } from "../utils/track";
import { withpath } from '../utils/index.js'
import * as s from "./sequence.module.css"

const Sequence = (p) => {

	const data = p.data;

	const paths = [
		withpath(`/sequences/0/`),
		withpath(`/sequences/building/`),
		withpath(`/sequences/site/`),
		withpath(`/sequences/office/`)
	]

	const [num, setNum] = useState(0);

	useEffect(() => {
		setNum(0)
	},[p.titles])

	return (
		<section 
			className={s.sequence}
			id={`sequence`}
		>

			<div data-seq={`${p.section}_${num}`} className={s.sequencebox}>


			{
				(p.titles === 1) &&

				<Tridi
					className={s.tridi}
					location={paths[1]} 
					format="png" 
					count={"15"}
					dragInterval={10}
					inverse
					onDragStart={()=>{
						if(!p.rotate)
							p.setRotate(true)
					}}
					onFrameChange={(d)=>{
						setNum(d)
						// console.log(d)
					}}
				/>
			}

			{
				(p.titles === 2) &&

				<Tridi
					className={s.tridi}
					location={paths[2]} 
					format="png" 
					count={"15"}
					dragInterval={10}
					inverse
					onDragStart={()=>{
						if(!p.rotate)
							p.setRotate(true)
					}}
					onFrameChange={(d)=>{
						setNum(d)
						// console.log(d)
					}}
				/>
			}

			{
				(p.titles === 3) &&

				<Tridi
					className={s.tridi}
					location={paths[3]} 
					format="png" 
					count={"15"}
					dragInterval={10}
					inverse
					onDragStart={()=>{
						if(!p.rotate)
							p.setRotate(true)
					}}
					onFrameChange={(d)=>{
						setNum(d)
						// console.log(d)
					}}
				/>
			}


				{
					(p.titles === 1) &&
					<div 
						className={s.btn_inside}
						onClick={ () => { 
							track(`cta`,`cta`,`click`,`Explore the inside`);
							p.newSection(3) 
						}}
					>
						Explore the inside
						<div className={s.btn_line} />
					</div>
				}

				{
					(p.titles === 1) && <div className={s.sequence_markers}>
					{
						data[1].points.map((d,i)=>(
							<Marker 
								key={`pointer`+i} 
								data={d} 
								dataid={1} 
								num={num}
							/>
						))
					}
					</div>
				}

				{
					(p.titles === 2) && <div className={s.sequence_markers}>
					{
						data[2].points.map((d,i)=>(
							<Marker 
								key={`pointer`+i} 
								data={d} 
								dataid={2} 
								num={num}
							/>
						))
					}
					</div>
				}

				{
					(p.titles === 3) && <div className={s.sequence_markers}>
					{
						data[3].points.map((d,i)=>(
							<Marker 
								key={`pointer`+i} 
								data={d} 
								dataid={3} 
								num={num}
							/>
						))
					}
					</div>
				}

			</div>

		</section>
	)

}

export default Sequence