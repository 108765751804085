// extracted by mini-css-extract-plugin
export const reading = "reading-module--reading--3VUfQ";
export const reading2 = "reading-module--reading2--2tRvl";
export const h0 = "reading-module--h0--Rui9b";
export const links = "reading-module--links---Gm8m";
export const article = "reading-module--article--37c3P";
export const img = "reading-module--img--3PS1h";
export const imgbox = "reading-module--imgbox--V69WU";
export const txt = "reading-module--txt--2u-W7";
export const read = "reading-module--read--3f5jR";
export const readtxt = "reading-module--readtxt--1g_R6";
export const arrow = "reading-module--arrow--34Eee";