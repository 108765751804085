import React, { useRef } from 'react'
import { 
  useGLTF, 
  // useFBX, 
  useTexture 
} from '@react-three/drei'
import { 
  // meshStandardMaterial, 
  // meshLambertMaterial 
} from 'three'
import { withpath } from '../../utils/index.js'

export default function Model1(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF(withpath('/models/building/building.gltf'))
  // const [diffuseMap, reflectionMap] = useTexture(['models/building/AIT01W2W2_d.png', 'models/building/AIT01W2W2_si.png'])
  const [reflectionMap] = useTexture([withpath('/models/building/AIT01W2W2_si.png')])

  return (
    <group ref={group} {...props} dispose={null}>
        <group scale={[0.001,0.001,0.001]}>
          <group rotation={[-Math.PI / 2, 0, -Math.PI/2]}>
            <group rotation={[Math.PI / 2, 0, 0]}>
              <group rotation={[-Math.PI / 2, 0, 0]}>
                <mesh
                  castShadow
                  receiveShadow
                  geometry={nodes.AIT01W2W2_AIT01W2W2_0.geometry}
                  material={materials.AIT01W2W2}
                  material-roughnessMap={reflectionMap}
                >
                </mesh>
              </group>
            </group>
        </group>
      </group>
    </group>
  )
}