// extracted by mini-css-extract-plugin
export const marker = "marker-module--marker--OHXeH";
export const mrkr = "marker-module--mrkr--2opgC";
export const mrkr2 = "marker-module--mrkr2--2djg8";
export const circle = "marker-module--circle--1Vwj2";
export const box = "marker-module--box--3Cr88";
export const boxl = "marker-module--boxl--Cag-Y";
export const txt = "marker-module--txt--7lJN5";
export const line1 = "marker-module--line1--1QBVW";
export const line1l = "marker-module--line1l--2ew5Z";
export const line2 = "marker-module--line2--1aXA6";
export const line2l = "marker-module--line2l--1ymA2";
export const pointer = "marker-module--pointer--le1l9";
export const pcircle = "marker-module--pcircle--29SFi";
export const pimg = "marker-module--pimg--1TGK6";
export const tippy = "marker-module--tippy--rgJ_i";
export const img = "marker-module--img--KOsKX";
export const tbox = "marker-module--tbox--1mfZj";
export const h0 = "marker-module--h0--3ec6T";
export const h0line = "marker-module--h0line--2X1jl";
export const txtt = "marker-module--txtt--2Aet8";