import React, { useState, useRef }  from 'react'
import * as s from "./main.module.css"

import Reading from "./reading"
import Article1 from "./article1"
import Article2 from "./article2"
import Article3 from "./article3"

import Close from '../assets/close.svg';
import { track } from "../utils/track";


const Modal = (p) => {

	const ref = useRef()
	
	let modal = p.modal
	let setModal = p.setModal
	let data =  p.data

	const [modal1, setModal1] = useState([false,false,false,false])
	const [modal2, setModal2] = useState([false,false,false,false])
	const [modal3, setModal3] = useState([false,false,false,false])


	const handleScroll = e => {
		
		let scrollTop = ref.current.scrollTop;
		let scrollHeight = ref.current.scrollHeight;
		let clientHeight = ref.current.clientHeight;
		let scrollPercent = (scrollTop / (scrollHeight - clientHeight)) * 100;

		if(modal === 1){
			if(scrollPercent>=25 && !modal1[0]){
				let nm = modal1;
				nm[0] = true;
				setModal1(nm)
				track(`event`, `page`, `scrolldepth`, `miniarticle 1 - 25%`);
			}
			if(scrollPercent>=50 && !modal1[1]){
				let nm = modal1;
				nm[1] = true;
				setModal1(nm)
				track(`event`, `page`, `scrolldepth`, `miniarticle 1 - 50%`);
			}
			if(scrollPercent>=75 && !modal1[2]){
				let nm = modal1;
				nm[2] = true;
				setModal1(nm)
				track(`event`, `page`, `scrolldepth`, `miniarticle 1 - 75%`);
			}
			if(scrollPercent>=100 && !modal1[3]){
				let nm = modal1;
				nm[3] = true;
				setModal1(nm)
				track(`event`, `page`, `scrolldepth`, `miniarticle 1 - 100%`);
			}
		}

		if(modal === 2){
			if(scrollPercent>=25 && !modal2[0]){
				let nm = modal2;
				nm[0] = true;
				setModal2(nm)
				track(`event`, `page`, `scrolldepth`, `miniarticle 2 - 25%`);
			}
			if(scrollPercent>=50 && !modal2[1]){
				let nm = modal2;
				nm[1] = true;
				setModal2(nm)
				track(`event`, `page`, `scrolldepth`, `miniarticle 2 - 50%`);
			}
			if(scrollPercent>=75 && !modal2[2]){
				let nm = modal2;
				nm[2] = true;
				setModal2(nm)
				track(`event`, `page`, `scrolldepth`, `miniarticle 2 - 75%`);
			}
			if(scrollPercent>=100 && !modal2[3]){
				let nm = modal2;
				nm[3] = true;
				setModal2(nm)
				track(`event`, `page`, `scrolldepth`, `miniarticle 2 - 100%`);
			}
		}

		if(modal === 3){
			if(scrollPercent>=25 && !modal3[0]){
				let nm = modal3;
				nm[0] = true;
				setModal3(nm)
				track(`event`, `page`, `scrolldepth`, `miniarticle 3 - 25%`);
			}
			if(scrollPercent>=50 && !modal3[1]){
				let nm = modal3;
				nm[1] = true;
				setModal3(nm)
				track(`event`, `page`, `scrolldepth`, `miniarticle 3 - 50%`);
			}
			if(scrollPercent>=75 && !modal3[2]){
				let nm = modal3;
				nm[2] = true;
				setModal3(nm)
				track(`event`, `page`, `scrolldepth`, `miniarticle 3 - 75%`);
			}
			if(scrollPercent>=100 && !modal3[3]){
				let nm = modal3;
				nm[3] = true;
				setModal3(nm)
				track(`event`, `page`, `scrolldepth`, `miniarticle 3 - 100%`);
			}
		}
	}

	return (

		<div
			className={s.modal} 
			style={{visibility: (modal===0)?`hidden`:`visible`, opacity: (modal===0)?0:1}}
		>
			<div
				ref={ref}
				className={s.modalbox} 
				id={`modalbox`} 
				onScroll={handleScroll}
			>
				<Article1 data={data.articles[0]} setModal={setModal} modal={modal} />
				<Article2 data={data.articles[1]} setModal={setModal} modal={modal} />
				<Article3 data={data.articles[2]} setModal={setModal} modal={modal} />
				<Reading data={data} setModal={setModal} modal={modal}/>
				<div className={s.modalpadding} />
			</div>
			<div 
				className={s.close}
				role = "button"
				tabIndex="0"
				aria-label="Close"
				onKeyDown={()=>{}}
				onClick={()=>{
					track(`cta`,`cta`,`click`,`close ` + data.articles[modal-1].h0);
					setModal(0);
				}}
			><Close /></div>
		</div>

)}

export default Modal
