// extracted by mini-css-extract-plugin
export const mrkr = "marker_s-module--mrkr--hMzF1";
export const box = "marker_s-module--box--1HJZv";
export const marker = "marker_s-module--marker--PSNVF";
export const circle = "marker_s-module--circle--2LVBB";
export const boxl = "marker_s-module--boxl--14YkV";
export const txt = "marker_s-module--txt--5eALD";
export const line1 = "marker_s-module--line1--3Ujcs";
export const line1l = "marker_s-module--line1l--3k8Ay";
export const line2 = "marker_s-module--line2--24tZv";
export const line2l = "marker_s-module--line2l--3by8Q";
export const pointer = "marker_s-module--pointer--2GwQW";
export const pcircle = "marker_s-module--pcircle--3vCqI";
export const pimg = "marker_s-module--pimg--12mWR";
export const tippy = "marker_s-module--tippy--B4NXB";
export const img = "marker_s-module--img--HDFiJ";
export const tbox = "marker_s-module--tbox--2WJ1G";
export const h0 = "marker_s-module--h0--2_zsN";
export const h0line = "marker_s-module--h0line--1nfIc";
export const txtt = "marker_s-module--txtt--3QgJK";