// extracted by mini-css-extract-plugin
export const scene = "scene-module--scene--dgIBS";
export const canvas = "scene-module--canvas--39jM1";
export const canvas0 = "scene-module--canvas0--5TqKq";
export const controls = "scene-module--controls--1oaHs";
export const back = "scene-module--back--32DlH";
export const loader = "scene-module--loader--1p9l1";
export const forw = "scene-module--forw--gGKJI";
export const forwtxt = "scene-module--forwtxt--3zm-E";
export const rotate = "scene-module--rotate--2wj4I";
export const rotatea = "scene-module--rotatea--1uDs8";
export const rotateb = "scene-module--rotateb--2_3Hw";
export const markerbox3 = "scene-module--markerbox3--2e5Qk";
export const marker3 = "scene-module--marker3--3QRsC";
export const circle3 = "scene-module--circle3--1QTcK";
export const box3 = "scene-module--box3--1CTE6";
export const txt3 = "scene-module--txt3--1kdWa";