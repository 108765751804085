import React, { Suspense } from "react"
import { Canvas } from "@react-three/fiber"
import { Environment, Plane, Sky, AdaptiveDpr } from "@react-three/drei"
import * as THREE from 'three';

import Model1 from './models/building'
import Model2 from './models/site'
import Model3 from './models/office'
import Marker from "./marker";
import Markerlink from "./markerlink";

import Effects from './effects'
import Lights from "./lights";
import Orbit from "./orbit";
import Loader from "./loader";
import { withpath } from '../utils/index.js'
import * as s from "./scene.module.css"

const Scene = (p) => {

	const data = p.data;

	return (
		<section className={s.scene}>
			<Canvas
				className={s.canvas + ((p.section===0)?(` ` + s.canvas0):``)}
				gl={{
					physicallyCorrectLights:true,
					powerPreference: "high-performance",
					alpha: false,
					antialias: false,
					stencil: false,
					depth: false
				}}
				camera={{ position: [0, 10, 25], fov: 34, near:(p.size==="XS")?20:10, far:(p.size==="XS")?30:40 }}
				raycaster
				shadows
				dpr={[1, 2]}
				performance={{ min: 0.5 }}
				colorManagement
				frameloop={`demand`}
				mode={`concurrent`}
				onCreated={({ gl, canvas, scene, camera }) => {
					gl.toneMapping = THREE.ACESFilmicToneMapping
					gl.outputEncoding = THREE.sRGBEncoding
					scene.background = new THREE.Color('#e7eef4')
				}}
			>
				<axesHelper args={[0]} />
				<Suspense fallback={null}>

					<group 
						position={[0,p.main,0]} 
						scale={[p.scale, p.scale, p.scale]}
					>
						<Plane
							receiveShadow
							rotation={[-Math.PI / 2, 0, 0]}
							position={[0, 0, 0]}
							args={[1000, 1000]}
						>
							<shadowMaterial attach='material' opacity={0.5} color="black"/>
						</Plane>

						<group position={p.model3} rotation={[0,-0.5,0]}>
							<Model3 />
						</group>

						<group position={p.model2} rotation={[0,2,0]}>
							<Model2 />
						</group>

						<group position={p.model1} rotation={[0,1,0]}>
							<Model1 />
						</group>

						{
							(p.titles === 1) && <group position={[0,0,0]} rotation={[0,1,0]}>
							{
								data[1].points.map((d,i)=>(
									<Marker key={`pointer`+i} data={d} dataid={1} mobile={(p.size==="XS")} />
								))
							}
							<Markerlink newSection={p.newSection} />
							</group>
						}

						{
							(p.titles === 2) && <group position={[0,0,0]} rotation={[0,2,0]}>
							{
								data[2].points.map((d,i)=>(
									<Marker key={`pointer`+i} data={d} dataid={2} mobile={(p.size==="XS")}/>
								))
							}
							</group>
						}

						{
							(p.titles === 3) && <group position={[0,-0.5,0]} rotation={[0,-0.5,0]} >
							{
								data[3].points.map((d,i)=>(
									<Marker key={`pointer`+i} data={d} dataid={3} mobile={(p.size==="XS")}/>
								))
							}
							</group>
						}

					</group>

					<Environment background={false} files={withpath(`/skybox/studio.hdr`)} />

				</Suspense>
				<Sky 
					sunPosition={[0, 1, 0]} 
					inclination={1} 
					azimuth={0.25} 
					rayleigh={0.2}
					exposure={1}
					mieCoefficient={0.005}
					mieDirectionalG={0.8}
				/>
				<Lights section={p.section} />
				<AdaptiveDpr />
				<Effects />
				<Orbit
					size={p.size}
					titles={p.titles}
					section={p.section} 
					rotate={p.rotate} 
					setRotate={p.setRotate}
				/>

			</Canvas>
			<Loader isLoaded={p.isLoaded} setIsLoaded={p.setIsLoaded}/>
		</section>
	)
}

export default Scene