import React from 'react'

const Lights = p => {
	return (
		<directionalLight 
			intensity={(p.section===1)?1.2:0}
			position={[-20, 24, 10]}
			castShadow
			shadow-mapSize-height={2048}
			shadow-mapSize-width={2048}
		/>
	)
}

export default Lights