import React, { useState, useEffect, useRef } from "react";
import { track } from "../utils/track";
import { Html } from "@react-three/drei";
import * as s from "./marker.module.css"
import * as THREE from 'three';

export default function Markerlink(p){

  const mark = useRef();
  const [hidden, set] = useState()
  const [sector, setSector] = useState(0)
  useEffect(() => {})



  return (

      <Html
        ref={mark}
        position={[-1.6,1.6,-1.2]}
        zIndexRange={[1, 1]}
        calculatePosition={
          
          (el, camera, size) => {
            const widthHalf = size.width / 2;
            const heightHalf = size.height / 2;
            let nSector = 1
            let v1 = new THREE.Vector3();
            let objectPos = v1.setFromMatrixPosition(el.matrixWorld);
            objectPos.project(camera);
            objectPos.x = ( objectPos.x * widthHalf ) + widthHalf;
            objectPos.y = - ( objectPos.y * heightHalf ) + heightHalf;

            if((objectPos.x >= widthHalf) && (objectPos.y <= heightHalf)) nSector = 0
            if((objectPos.x <= widthHalf) && (objectPos.y <= heightHalf)) nSector = 1
            if((objectPos.x <= widthHalf) && (objectPos.y >= heightHalf)) nSector = 2
            if((objectPos.x >= widthHalf) && (objectPos.y >= heightHalf)) nSector = 3
            if(nSector !== sector)
              setSector(nSector)

            return [objectPos.x, objectPos.y];
          }}
        // transform
        // sprite
        // align={"right"}
        // scale={0.35}
        scaleFactor={100}
        occlude
        onOcclude={set}
        className={s.mrkr}
        style={{
          opacity: hidden ? 0.2 : 1
        }}
      >

        <div 
          className={s.marker + ` pictetmarker`}
          role = "button"
          tabIndex="0"
          onKeyDown={()=>{
          }}
          onClick={()=>{
            track(`cta`,`cta`,`click`,`Explore the inside`);
            p.newSection(3);
          }}
        >
          <div className={s.line1l} data-id={"3"} dir={sector}/>
          <div className={s.line2l} dir={sector} />
          <div className={s.circle} data-id={"3"} />

          <div className={s.boxl} dir={sector} >
            <div className={s.txt}  dir={sector} data-id={"3"} >{`Explore the inside`}</div>
          </div>
        </div>

      </Html>

  );
}