import React, { useState, useEffect } from 'react'
import * as s from "./main.module.css"
import Scene from "./scene"

import Sequence from "./sequence"

import Controls from "./controls"
import Reading from "./reading"

import Modal from "./modal"
import useSize from '../utils/useSize';

import { gsap } from "gsap/dist/gsap";

import { getGPUTier } from 'detect-gpu';
import { withpath } from '../utils/index.js'

let detected = false;
let TIME = 0.75

const Main = (props) => {

	const size = useSize();

	if(!detected){
		(async () => {
			const gpuTier = await getGPUTier();
			detected = true;
			setTier(gpuTier.tier)
			if(gpuTier.tier === 0)
			{
				setIsLoaded(true);
				TIME = 0.3;
			}
		})();
	}

	gsap.config({
		autoSleep: 60,
		force3D: false,
		nullTargetWarn: false
	});

	const DELTA = 30
	const SCALES = {
		"XS":[0.38, 0.7],
		"MD":[0.8, 0.9],
		"LG":[1.2, 1.2]
	}

	const data = props.data;
	const [isLoaded, setIsLoaded] = useState(false);
	const [section, setSection] = useState(0);
	const [titles, setTitles] = useState(0);
	const [rotate, setRotate] = useState(false);
	const [tier, setTier] = useState(1);

	const [modal, setModal] = useState(0);
	const [animated, setAnimated] = useState(false);

	const [scale, setScale] = useState(SCALES[size][0])

	const start1 = [-3.25,0,0]
	const start2 = [3.25,0,0]
	const start3 = [0,0,-DELTA]

	const startMain = (size==="XS")?0.4:-1;
	const finishMain = (size==="XS")?2:0;
	const finish3Main = (size==="XS")?2.5:1;

	const [model1, setModel1] = useState(start1)
	const [model2, setModel2] = useState(start2)
	const [model3, setModel3] = useState(start3)
	const [main, setMain] = useState(startMain)

	useEffect(() => {
		document.getElementById('modalbox').scrollTop = 0;
	},[modal])

	useEffect(() => {
		setScale(SCALES[size][0])
	},[size])



	function newSection(n){
		if(!animated){
			setAnimated(true);
			if((section === 0) && (n === 1)){
				from0to1();
			}
			if((section === 0) && (n === 2)){
				from0to2();
			}
			if((section === 1) && (n === 0)){
				from1to0();
			}
			if((section === 2) && (n === 0)){
				from2to0();
			}
			if((section === 1) && (n === 2)){
				from1to2();
			}
			if((section === 2) && (n === 1)){
				from2to1();
			}
			if((section === 1) && (n === 3)){
				from1to3();
			}
			if((section === 3) && (n === 1)){
				from3to1();
			}
			if((section === 3) && (n === 2)){
				from3to2();
			}
			if((section === 3) && (n === 0)){
				from3to0();
			}
		}
	}

	//////////////////////////// transitions
	function from0to1(){
		let btn0 = document.querySelector(`#pictetbtn0`)
		let btn1 = document.querySelector(`#pictetbtn1`)
		let start = document.querySelector(`#start`);
		let txt = document.querySelector(`#pictettxtbox`)
		let desc = document.querySelector(`#pictetdesc`)
		let desc2 = document.querySelector(`#pictetdescbox`);
		let legend = document.querySelector(`#legendbox`);
		let btns = document.querySelector(`#modellinkbox`);
		let rotate = document.querySelector(`#rotatebox`);
		let sequence = document.querySelector(`#sequence`);
		let counter0 = { var: SCALES[size][0] };
		let counter1 = { var: start1[0] };
		let counter2 = { var: start2[0] };
		let counterMain = { var: startMain };

		let tl = gsap.timeline({ paused: false });
			tl.to(btn0, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.0);
			tl.to(btn1, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.0);
			tl.to(start, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.0);
			tl.to(desc, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.3);
			tl.to(txt, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.5);
			tl.to(counter0, {
				var: SCALES[size][1], 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setScale(counter0.var);} 
			}, 1.0)
			tl.to(counter1, {
				var: 0, 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setModel1([counter1.var,start1[1],start1[2]]);} 
			}, 1.0)
			tl.to(counter2, {
				var: DELTA, 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setModel2([counter2.var,start2[1],start2[2]]);} 
			}, 1.0)
			tl.to(counterMain, {
				var: finishMain, 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setMain(counterMain.var);} 
			}, 1.0)
			tl.call(setSection, [1], 1+TIME)
			tl.to(desc2, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME+1.0);
			tl.to(legend, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME+1.2);
			tl.to(btns, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME+1.5);
			tl.to(rotate, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME+1.5);
			tl.to(sequence, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME+1.5);
			tl.call(setTitles, [1], TIME+2.0)
			tl.call(setAnimated, [false], TIME+2.0)
	}
	function from1to0(){
		let btn0 = document.querySelector(`#pictetbtn0`)
		let btn1 = document.querySelector(`#pictetbtn1`)
		let start = document.querySelector(`#start`)
		let txt = document.querySelector(`#pictettxtbox`)
		let desc = document.querySelector(`#pictetdesc`)
		let desc2 = document.querySelector(`#pictetdescbox`);
		let legend = document.querySelector(`#legendbox`);
		let btns = document.querySelector(`#modellinkbox`);
		let rotate = document.querySelector(`#rotatebox`);
		let sequence = document.querySelector(`#sequence`);
		let counter0 = { var: SCALES[size][1] };
		let counter1 = { var: 0 };
		let counter2 = { var: DELTA };
		let counterMain = { var: finishMain };

		let tl = gsap.timeline({ paused: false });
			tl.call(setTitles, [0], 0.0)
			tl.to(btns, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.0);
			tl.to(rotate, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.0);
			tl.to(sequence, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.0);
			tl.to(legend, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.3);
			tl.to(desc2, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.5);
			tl.call(setSection, [0], 1.0)
			tl.to(counter0, {
				var: SCALES[size][0], 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setScale(counter0.var);} 
			}, 1.0)
			tl.to(counter1, {
				var: start1[0], 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setModel1([counter1.var,start1[1],start1[2]]);} 
			}, 1.0)
			tl.to(counter2, {
				var: start2[0], 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setModel2([counter2.var,start2[1],start2[2]]);} 
			}, 1.0)
			tl.to(counterMain, {
				var: startMain, 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setMain(counterMain.var);} 
			}, 1.0)
			tl.to(txt, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME+1.0);
			tl.to(desc, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME+1.2);
			tl.to(btn0, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME+1.5);
			tl.to(btn1, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME+1.5);
			tl.to(start, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME+1.5);
			tl.call(setAnimated, [false], TIME+2.0)
	}

	function from0to2(){
		let btn0 = document.querySelector(`#pictetbtn0`)
		let btn1 = document.querySelector(`#pictetbtn1`)
		let start = document.querySelector(`#start`)
		let txt = document.querySelector(`#pictettxtbox`)
		let desc = document.querySelector(`#pictetdesc`)
		let desc2 = document.querySelector(`#pictetdescbox`);
		let legend = document.querySelector(`#legendbox`);
		let btns = document.querySelector(`#modellinkbox`);
		let rotate = document.querySelector(`#rotatebox`);
		let sequence = document.querySelector(`#sequence`);
		let counter0 = { var: SCALES[size][0] };
		let counter1 = { var: start1[0] };
		let counter2 = { var: start2[0] };
		let counterMain = { var: startMain };

		let tl = gsap.timeline({ paused: false });
			tl.to(btn0, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.0);
			tl.to(btn1, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.0);
			tl.to(start, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.0);
			tl.to(desc, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.3);
			tl.to(txt, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.5);
			tl.to(counter0, {
				var: SCALES[size][1], 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setScale(counter0.var);} 
			}, 1.0)
			tl.to(counter1, {
				var: -DELTA, 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setModel1([counter1.var,start1[1],start1[2]]);} 
			}, 1.0)
			tl.to(counter2, {
				var: 0, 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setModel2([counter2.var,start1[1],start1[2]]);} 
			}, 1.0)
			tl.to(counterMain, {
				var: finishMain, 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setMain(counterMain.var);} 
			}, 1.0)
			tl.call(setSection, [2], TIME+1.0)
			tl.to(desc2, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME+1.0);
			tl.to(legend, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME+1.2);
			tl.to(btns, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME+1.5);
			tl.to(rotate, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME+1.5);
			tl.to(sequence, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME+1.5);
			tl.call(setTitles, [2], TIME+2.0);
			tl.call(setAnimated, [false], TIME+2.0)
	}
	function from2to0(){
		let btn0 = document.querySelector(`#pictetbtn0`)
		let btn1 = document.querySelector(`#pictetbtn1`)
		let start = document.querySelector(`#start`)
		let txt = document.querySelector(`#pictettxtbox`)
		let desc = document.querySelector(`#pictetdesc`)
		let desc2 = document.querySelector(`#pictetdescbox`);
		let legend = document.querySelector(`#legendbox`);
		let btns = document.querySelector(`#modellinkbox`);
		let rotate = document.querySelector(`#rotatebox`);
		let sequence = document.querySelector(`#sequence`);
		let counter0 = { var: SCALES[size][1] };
		let counter1 = { var: -DELTA };
		let counter2 = { var: 0 };
		let counterMain = { var: finishMain };

		let tl = gsap.timeline({ paused: false });
			tl.call(setTitles, [0], 0.0)
			tl.to(btns, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.0);
			tl.to(rotate, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.0);
			tl.to(sequence, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.0);
			tl.to(legend, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.3);
			tl.to(desc2, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.5);
			tl.call(setSection, [0], 1.0)
			tl.to(counter0, {
				var: SCALES[size][0],
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setScale(counter0.var);} 
			}, 1.0)
			tl.to(counter1, {
				var: start1[0], 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setModel1([counter1.var,start1[1],start1[2]]);} 
			}, 1.0)
			tl.to(counter2, {
				var: start2[0], 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setModel2([counter2.var,start2[1],start2[2]]);} 
			}, 1.0)
			tl.to(counterMain, {
				var: startMain, 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setMain(counterMain.var);} 
			}, 1.0)
			tl.to(txt, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME+1.0);
			tl.to(desc, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME+1.2);
			tl.to(btn0, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME+1.5);
			tl.to(btn1, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME+1.5);
			tl.to(start, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME+1.5);
			tl.call(setAnimated, [false], TIME+2.0)
	}

	function from1to2(){
		let rotate = document.querySelector(`#rotatebox`);
		let sequence = document.querySelector(`#sequence`);
		let counter1 = { var: 0 };
		let counter2 = { var: 20 };
		let tl = gsap.timeline({ paused: false });
			tl.call(setTitles, [0], 0.0)
			tl.to(rotate, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.0);
			tl.to(sequence, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.0);
			tl.to(counter1, {
				var: -DELTA, 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setModel1([counter1.var,start1[1],start1[2]]);} 
			}, 0.0)
			tl.to(counter2, {
				var: 0, 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setModel2([counter2.var,start2[1],start2[2]]);} 
			}, 0.0)
			tl.call(setSection, [2], TIME/2)
			tl.call(setTitles, [2], TIME)
			tl.to(rotate, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME);
			tl.to(sequence, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME);
			tl.call(setAnimated, [false], TIME)
	}
	function from2to1(){
		let rotate = document.querySelector(`#rotatebox`);
		let sequence = document.querySelector(`#sequence`);
		let counter1 = { var: -DELTA };
		let counter2 = { var: 0 };
		let tl = gsap.timeline({ paused: false });
			tl.call(setTitles, [0], 0.0)
			tl.to(rotate, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.0);
			tl.to(sequence, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.0);
			tl.to(counter1, {
				var: 0, 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setModel1([counter1.var,start1[1],start1[2]]);} 
			}, 0.0)
			tl.to(counter2, {
				var: DELTA, 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setModel2([counter2.var,start2[1],start2[2]]);} 
			}, 0.0)
			tl.call(setSection, [1], TIME/2)
			tl.call(setTitles, [1], TIME)
			tl.to(rotate, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME);
			tl.to(sequence, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME);
			tl.call(setAnimated, [false], TIME)
	}

	function from1to3(){
		let rotate = document.querySelector(`#rotatebox`);
		let sequence = document.querySelector(`#sequence`);
		let counter1 = { var: 0 };
		let counter3 = { var: start3[2] };
		let counterMain = { var: finishMain };
		let tl = gsap.timeline({ paused: false });
			tl.call(setTitles, [0], 0.0)
			tl.to(rotate, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.0);
			tl.to(sequence, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.0);
			tl.to(counter1, {
				var: -DELTA, 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setModel1([counter1.var,start1[1],start1[2]]);} 
			}, 0.0)
			tl.to(counter3, {
				var: 0, 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setModel3([start3[0],start3[1],counter3.var]);} 
			}, 0.0)
			tl.to(counterMain, {
				var: finish3Main, 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setMain(counterMain.var);} 
			}, 0.0)
			tl.call(setSection, [3], TIME/2)
			tl.call(setTitles, [3], TIME)
			tl.to(rotate, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME);
			tl.to(sequence, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME);
			tl.call(setAnimated, [false], TIME)
	}

	function from3to1(){
		let rotate = document.querySelector(`#rotatebox`);
		let sequence = document.querySelector(`#sequence`);
		let counter1 = { var: -DELTA };
		let counter3 = { var: 0 };
		let counterMain = { var: finish3Main };
		let tl = gsap.timeline({ paused: false });
			tl.call(setTitles, [0], 0.0)
			tl.to(rotate, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.0);
			tl.to(sequence, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.0);
			tl.to(counter1, {
				var: 0, 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setModel1([counter1.var,start1[1],start1[2]]);} 
			}, 0.0)
			tl.to(counter3, {
				var: start3[2], 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setModel3([start3[0],start3[1],counter3.var]);} 
			}, 0.0)
			tl.to(counterMain, {
				var: finishMain, 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setMain(counterMain.var);} 
			}, 0.0)
			tl.call(setSection, [1], TIME/2)
			tl.call(setTitles, [1], TIME)
			tl.to(rotate, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME);
			tl.to(sequence, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME);
			tl.call(setAnimated, [false], TIME)
	}

	function from3to2(){
		let rotate = document.querySelector(`#rotatebox`);
		let sequence = document.querySelector(`#sequence`);
		let counter2 = { var: DELTA };
		let counter3 = { var: 0 };
		let counterMain = { var: finish3Main };
		let tl = gsap.timeline({ paused: false });
			tl.call(setTitles, [0], 0.0)
			tl.to(rotate, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.0);
			tl.to(sequence, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.0);
			tl.to(counter2, {
				var: 0, 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setModel2([counter2.var,start2[1],start2[2]]);} 
			}, 0.0)
			tl.to(counter3, {
				var: start3[2], 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setModel3([start3[0],start3[1],counter3.var]);} 
			}, 0.0)
			tl.to(counterMain, {
				var: finishMain, 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setMain(counterMain.var);} 
			}, 0.0)
			tl.call(setSection, [2], TIME/2)
			tl.call(setTitles, [2], TIME)
			tl.to(rotate, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME);
			tl.to(sequence, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME);
			tl.call(setAnimated, [false], TIME)
	}

	function from3to0(){
		let btn0 = document.querySelector(`#pictetbtn0`)
		let btn1 = document.querySelector(`#pictetbtn1`)
		let start = document.querySelector(`#start`)
		let txt = document.querySelector(`#pictettxtbox`)
		let desc = document.querySelector(`#pictetdesc`)
		let desc2 = document.querySelector(`#pictetdescbox`);
		let legend = document.querySelector(`#legendbox`);
		let btns = document.querySelector(`#modellinkbox`);
		let rotate = document.querySelector(`#rotatebox`);
		let sequence = document.querySelector(`#sequence`);
		let counter0 = { var: SCALES[size][1] };
		let counter1 = { var: -DELTA };
		let counter2 = { var: DELTA };
		let counter3 = { var: 0 };
		let counterMain = { var: finish3Main };

		let tl = gsap.timeline({ paused: false });
			tl.call(setTitles, [0], 0.0)
			tl.to(btns, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.0);
			tl.to(rotate, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.0);
			tl.to(sequence, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.0);
			tl.to(legend, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.3);
			tl.to(desc2, {autoAlpha: 0, duration: 0.5, ease: `power1.out` }, 0.5);
			tl.call(setSection, [0], 1.0)
			tl.to(counter0, {
				var: SCALES[size][0], 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setScale(counter0.var);} 
			}, 1.0)
			tl.to(counter1, {
				var: start1[0], 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setModel1([counter1.var,start1[1],start1[2]]);} 
			}, 1.0)
			tl.to(counter2, {
				var: start2[0], 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setModel2([counter2.var,start2[1],start2[2]]);} 
			}, 1.0)
			tl.to(counter3, {
				var: start3[2], 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setModel3([start3[0],start3[1],counter3.var]);} 
			}, 1.0)
			tl.to(counterMain, {
				var: startMain, 
				duration: TIME, 
				ease: `none`, 
				onUpdate:function(){setMain(counterMain.var);} 
			}, 1.0)
			tl.to(txt, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME+1.0);
			tl.to(desc, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME+1.2);
			tl.to(btn0, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME+1.5);
			tl.to(btn1, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME+1.5);
			tl.to(start, {autoAlpha: 1, duration: 0.5, ease: `power1.out` }, TIME+1.5);
			tl.call(setAnimated, [false], TIME+0.2)
	}
	//////////////////////////// transitions


	return (
		<section className={s.main}>

		{
			(tier === 0) &&
			<Sequence
				section={section}
				setSection={setSection}
				titles={titles}
				newSection={newSection} 
				data={data.sections} 
				rotate={rotate}
				setRotate={setRotate}
			/>
		}

		{
			(tier === 0) &&
			<div className={s.start} id={`start`}>
				<img 
					className={s.start1}
					src={withpath(`/sequences/building/13.png`)}
					alt={``}
				/>
				<img 
					className={s.start2}
					src={withpath(`/sequences/site/2.png`)}
					alt={``}
				/>
			</div>
		}



		{
			(tier !== 0) &&
			<Scene 
				section={section} 
				setSection={setSection}
				titles={titles}
				newSection={newSection} 
				data={data.sections} 
				model1={model1} 
				model2={model2}
				model3={model3}
				isLoaded={isLoaded}
				setIsLoaded={setIsLoaded}
				main={main}
				rotate={rotate}
				setRotate={setRotate}
				scale={scale}
				size={size}
				tier={tier}
			/>
		}

			<div className={s.header} >
				<div className={s.h0} dangerouslySetInnerHTML={{__html: data.sections[0].h0 }}/>
				<div className={s.line} />
			</div>

			<Controls 
				data={data.sections} 
				newSection={newSection} 
				section={section} 
				isLoaded={isLoaded} 
				rotate={rotate}
				size={size}
			/>

			<Reading data={data.articles} setModal={setModal} modal={0}/>
			<Modal modal={modal} setModal={setModal} data={data.articles}/>

		</section>
)}

export default Main
