// extracted by mini-css-extract-plugin
export const article = "article-module--article--2RMpP";
export const headerbg = "article-module--headerbg--2uRbn";
export const headerbox = "article-module--headerbox--31qeH";
export const h0 = "article-module--h0--3U7bN";
export const desc = "article-module--desc--1SXkp";
export const txtbox = "article-module--txtbox--2_bTu";
export const txtss = "article-module--txtss--fdwL2";
export const txts = "article-module--txts--2MG2K";
export const txt = "article-module--txt--uZuli";
export const art1_1 = "article-module--art1_1--3WJim";
export const art1_2 = "article-module--art1_2--3pjcy";
export const art1_3 = "article-module--art1_3--3TXe3";
export const art2_1 = "article-module--art2_1--2jNvc";
export const art2_2 = "article-module--art2_2--3HFQd";
export const art2_3 = "article-module--art2_3--2nTfi";
export const art2_4 = "article-module--art2_4--1mMZl";
export const art3_1 = "article-module--art3_1--3EhMO";
export const art3_2 = "article-module--art3_2--3bpB0";
export const art3_3 = "article-module--art3_3--18qSP";
export const quotesbox = "article-module--quotesbox--lZXA8";
export const quotestxt = "article-module--quotestxt--2Aec5";
export const line = "article-module--line--2T5M7";
export const back = "article-module--back--2gIrF";