import React, { useState, useEffect, useRef } from "react";
import Tippy from '@tippyjs/react/headless';
import { gsap } from "gsap/dist/gsap";
import * as s from "./marker_s.module.css"
import { withpath } from '../utils/index.js'
const tip = `.` + s.tippy

export default function Marker(p){
  const data = p.data;
  const mark = useRef();
  const [sector, setSector] = useState(data.dir)
  const [loaded, setLoaded] = useState()
  
  function onMount() {
      gsap.set(tip, {autoAlpha: 0, scale: 0.85, transformOrigin:"50% 50%"});
      gsap.to(tip, {autoAlpha: 1, scale: 1, duration: 0.5, ease: `power2.out`});
  }
  function onHide({ unmount }) {
      gsap.set(tip, {autoAlpha: 0, scale: 0.85, transformOrigin:"50% 50%"});
  }

  function onLoad(){
    setLoaded(true);
  }

  useEffect(() => {

    let left = mark.current.offsetLeft
    let top = mark.current.offsetTop
    let dir = 0

    if((left > 500)&&(top <= 281)) dir = 0
    if((left <= 500)&&(top <= 281)) dir = 1
    if((left <= 500)&&(top > 281)) dir = 2
    if((left > 500)&&(top > 281)) dir = 3

    setSector(dir)
  })



  return (
      <div
        ref={mark}
        data-num={p.num}
        data-id={data.id}
        className={s.mrkr}
        style={{ opacity:loaded?1:0 }}
      >

        <div 
          className={s.marker + ` pictetmarker`}
          >
          <div className={s.line1} data-id={p.data.type} dir={sector}/>
          <div className={s.line2} data-id={p.data.type} dir={sector} />
          <div className={s.circle} data-id={p.data.type} />

          <Tippy
            placement={`auto`}
            trigger={`mouseenter`}
            onMount={onMount}
            onHide={onHide}
            zIndex={999999}
            render={attrs => (
              <div 
                className={s.tippy}
                data-id={data.type}
                {...attrs}
              >
                <img 
                  className={s.img}
                  src={withpath(`/img/popup/p${data.id}.jpg`)}
                  alt={``}
                />
                <div className={s.tbox}>
                  <div className={s.h0} dangerouslySetInnerHTML={{__html: data.name }}/>
                  <div className={s.h0line} />
                  {
                    data.txt.map((d,i)=>(
                      <div key={'txt'+i} className={s.txtt} dangerouslySetInnerHTML={{__html: d }} />
                    ))
                  }
                </div>
              
              </div>

            )}
          >
            <img 
                className={s.pimg}
                src={withpath(`/img/pointers/p${data.id}.png`)}
                alt={``}
                data-id={data.type}
                dir={sector}
                onLoad={onLoad}
            />
          </Tippy>

          <div className={s.box} dir={sector}>
            <div className={s.txt}  dir={sector} data-id={p.data.type} >{p.data.name}</div>
          </div>
        </div>

      </div>

  );
}