import React from 'react'
import * as s from "./article.module.css"

import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

import Icon from '../assets/article1.svg';
import Quotes from '../assets/quotes.svg';
// import Arrow from '../assets/arr.svg';
import { withpath } from '../utils/index.js'

const Article1 = (p) => {

	const data = p.data;

	return (
		<section className={s.article} style={{display: (p.modal===1)?`block`:`none`}} >
			<div 
				className={s.headerbg} 
				style={{backgroundImage: `url(${withpath(`/img/articles/bg1.jpg`)})` }}
			>
				<div className={s.headerbox}>
					<Icon />
					<div className={s.h0} dangerouslySetInnerHTML={{__html: data.h0 }} />
					<div className={s.desc} dangerouslySetInnerHTML={{__html: data.desc }} />
				</div>
			</div>
			<div className={s.txtbox}>
				
				<div className={s.txtss} data-id={1}>
					<div className={s.txts}>
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[0] }} data-id={0} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[1] }} data-id={1} />
					</div>
					
					<ScrollAnimation animateIn="fadeIn" animateOnce={true} scrollableParentSelector={`#modalbox`}>
					<div>
						<img 
							className={s.art1_1}
							src={withpath(`/img/articles/art1_1.png`)}
							alt=""
						/>
						</div>
					
					</ScrollAnimation>
					
				</div>

				<div className={s.txtss} data-id={2}>
					
					<ScrollAnimation animateIn="fadeIn" animateOnce={true} scrollableParentSelector={`#modalbox`}>
					<div>
						<img 
							className={s.art1_2}
							src={withpath(`/img/articles/art1_2.png`)}
							alt=""
						/></div>
					
					</ScrollAnimation>
					
					<div className={s.txts}>
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[2] }} data-id={2} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[3] }} data-id={3} />
					</div>
				</div>

				<div className={s.txtss}>
					<div className={s.txts}>
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[4] }} data-id={4} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[5] }} data-id={5} />
					</div>
					
					<ScrollAnimation animateIn="fadeIn" animateOnce={true} scrollableParentSelector={`#modalbox`}>
					<div>
						<img 
							className={s.art1_3}
							src={withpath(`/img/articles/art1_3.png`)}
							alt=""
						/></div>
					
					</ScrollAnimation>
					
				</div>

				<div className={s.txtss} data-id={7}>
					<div className={s.txts}>
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[6] }} data-id={6} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[7] }} data-id={7} />
					</div>
				</div>

				<div className={s.quotesbox}>
					<Quotes />
					<div className={s.quotestxt} dangerouslySetInnerHTML={{__html: data.quotes }} />
				</div>
				
				<div className={s.line} />



			</div>
		</section>
)}

export default Article1