import React, { useEffect, useState } from "react"
import Spinner from '../assets/spinner.svg';
import { useProgress } from "@react-three/drei"
import { withpath } from '../utils/index.js'
import * as s from "./loader.module.css"

export default function Loader(p) {

	const [percent, setPercent] = useState(0);
	// const { active, progress, errors, item, loaded, total } = useProgress()
	const { progress } = useProgress()

	useEffect(() => {
		if(progress > percent)
			setPercent(progress);
		if(progress===100)
			p.setIsLoaded(true)
	}, [progress,percent,p])

	return (
		<section className={s.loader} style={{opacity: p.isLoaded?0:1}}>
			<img 
				className={s.img}
				src={withpath(`/img/loader.jpeg`)}
				alt={`Loading your experience…`}
			/>
			<div className={s.box}>
				<Spinner />
				<div className={s.percent} dangerouslySetInnerHTML={{__html: Math.round(percent) + `%`}} />
			</div>
			<div className={s.exp} dangerouslySetInnerHTML={{__html: `Loading your experience…` }} />
		</section>
	)
}