
import React, { useRef, useState, useEffect } from 'react'
// import { useGLTF } from '@react-three/drei'
import { FBXLoader, TGALoader } from 'three-stdlib';
import { withpath } from '../../utils/index.js'
import { 
  // useThree, 
  useLoader 
} from '@react-three/fiber'

const useFBXwTGA = path=>{
  return useLoader(FBXLoader, path, (loader) => {
    loader.manager.addHandler( /\.tga$/i, new TGALoader());
  });
}

const traverseFbx = (fbx, type, fn)=>{
  (fbx.children||[]).forEach(c=>{
    if(c.type === type){
      fn(c)
    }
    traverseFbx(c, type, fn)
  })
}

export default function Office(props) {
  const group = useRef()
  const lfbx = useFBXwTGA(withpath('/models/office/office.fbx'))

  // const scene = useThree()



  useEffect(()=>{
    traverseFbx(lfbx, 'Mesh', mesh=>{
      // console.log('trapgeo', mesh);
      (mesh.material.forEach ? mesh.material.forEach(m=>m.alphaTest = 0.90) : (mesh.material.alphaTest = 0.90))
      mesh.castShadow = true
      // mesh.receiveShadow = true
      
      // console.log(mesh.name)
      if(mesh.name.includes('Structure_Office_Modern_Walls')){
         // mesh.visible = true
         // mesh.material[0].transparent = true;
         mesh.material.opacity = 0.5;
      }
      if(mesh.name.includes('Ceiling')){
         mesh.visible = false
         mesh.geometry.dispose();
         mesh.material.dispose();
         mesh.removeFromParent()
         // scene.remove( mesh );
      }
    })
    setFbx(lfbx)
  },[lfbx])

  const [fbx, setFbx] = useState()
  // console.log('lfbx', lfbx)
  return (
    <group 
      ref={group} 
      {...props} 
      dispose={null} 
      
      >
        <group 
          rotation={[0, 0, 0]}
          position={[2.38, -0.025, 3.7]}
          scale={[0.30, 0.30, 0.30]}
          >
            <group rotation={[-Math.PI/2,0,0]}>
              {fbx && <primitive object={fbx} dispose={null}/> }
            </group>
      </group>
    </group>
    )
}
