import React, { useRef, useState, useEffect } from 'react'
// import { useGLTF } from '@react-three/drei'
import { FBXLoader, TGALoader } from 'three-stdlib';
import { useLoader } from '@react-three/fiber';
import { withpath } from '../../utils/index.js'


const useFBXwTGA = path=>{
  return useLoader(FBXLoader, path, (loader) => {
    loader.manager.addHandler( /\.tga$/i, new TGALoader());
  });
}

const traverseFbx = (fbx, type, fn)=>{
  (fbx.children||[]).forEach(c=>{
    if(c.type === type){
      fn(c)
    }
    traverseFbx(c, type, fn)
  })
}

export default function Site(props) {
  const group = useRef()
  const lfbx = useFBXwTGA(withpath('/models/site/site.fbx'))

  useEffect(()=>{
    traverseFbx(lfbx, 'Mesh', mesh=>{
      // console.log('trapgeo', mesh);
      (mesh.material.forEach ? mesh.material.forEach(m=>m.alphaTest = 0.99) : (mesh.material.alphaTest = 0.99))
      mesh.castShadow = true
      mesh.receiveShadow = true
    })
    setFbx(lfbx)
  },[lfbx])

  const [fbx, setFbx] = useState()

  
  return (
    <group 
      ref={group} 
      {...props} 
      dispose={null} 
      >
        <group 
          rotation={[-Math.PI / 2, 0, Math.PI/4]}
          position={[0, 0.1, 0]}
          scale={[0.05, 0.05, 0.05]}
          >
              {fbx && <primitive object={fbx} dispose={null}/> }
      </group>
    </group>
  )
}