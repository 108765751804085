// extracted by mini-css-extract-plugin
export const main = "main-module--main--1FvNJ";
export const header = "main-module--header--3dTIO";
export const start = "main-module--start--3U1h8";
export const start1 = "main-module--start1--2CZt3";
export const start2 = "main-module--start2--aOmqn";
export const h0 = "main-module--h0--NTeF_";
export const line = "main-module--line--19vrh";
export const modal = "main-module--modal--37NPU";
export const modalbox = "main-module--modalbox--2F6sb";
export const modalpadding = "main-module--modalpadding--1SyuM";
export const close = "main-module--close--7ADu_";
export const block = "main-module--block--2CYF8";
export const blockwindow = "main-module--blockwindow--2FPcc";
export const welcome = "main-module--welcome--FN7S7";
export const blocktxt = "main-module--blocktxt--3sbVx";
export const enter = "main-module--enter--2eX9C";
export const entertxt = "main-module--entertxt--j8X-Z";