import React, { useState, useRef } from "react";
import { Html } from "@react-three/drei";
import Tippy from '@tippyjs/react/headless';
import { gsap } from "gsap/dist/gsap";
import { track } from "../utils/track";
import * as s from "./marker.module.css"
import * as THREE from 'three';
import { withpath } from '../utils/index.js'
const tip = `.` + s.tippy

export default function Marker(p){
  const data = p.data;
  const position = p.data.pos;

  const mark = useRef();

  const mobile = p.mobile;

  const [hidden, set] = useState()
  const [sector, setSector] = useState(data.dir)
  const [loaded, setLoaded] = useState()
  
  function onMount() {
      gsap.set(tip, {autoAlpha: 0, scale: 0.85, transformOrigin:"50% 50%"});
      gsap.to(tip, {autoAlpha: 1, scale: 1, duration: 0.5, ease: `power2.out`});
  }
  function onHide({ unmount }) {
      gsap.set(tip, {autoAlpha: 0, scale: 0.85, transformOrigin:"50% 50%"});
  }

  function onLoad(){
    setLoaded(true);
  }

  return (
      <Html
        ref={mark}
        position={position}
        zIndexRange={[1, 1]}
        calculatePosition={
          
          (el, camera, size) => {
            const widthHalf = size.width / 2;
            const heightHalf = size.height / 2;
            let nSector = 0
            let v1 = new THREE.Vector3();
            let objectPos = v1.setFromMatrixPosition(el.matrixWorld);
            objectPos.project(camera);
            objectPos.x = ( objectPos.x * widthHalf ) + widthHalf;
            objectPos.y = - ( objectPos.y * heightHalf ) + heightHalf;

            if((objectPos.x >= widthHalf) && (objectPos.y <= heightHalf)) nSector = 0
            if((objectPos.x <= widthHalf) && (objectPos.y <= heightHalf)) nSector = 1
            if((objectPos.x <= widthHalf) && (objectPos.y >= heightHalf)) nSector = 2
            if((objectPos.x >= widthHalf) && (objectPos.y >= heightHalf)) nSector = 3
            if(nSector !== sector)
              setSector(nSector)

            return [objectPos.x, objectPos.y];
          }
        }
        scaleFactor={100}
        occlude
        onOcclude={set}
        className={s.mrkr}
        style={{
          opacity: hidden ? (p.dataid===3?1:0) : ((loaded)?1:0)
        }}
      >

        <div 
          className={s.marker + ` pictetmarker`}
          style={{
            visibility: hidden ? (p.dataid===3?`visible`:`hidden`) : `visible`
          }}
          >
          <div className={s.line1} data-id={p.data.type} dir={sector}/>
          <div className={s.line2} data-id={p.data.type} dir={sector} />
          <div className={s.circle} data-id={p.data.type} />

          <Tippy
            placement={(data.id==="24" && mobile)?`bottom`:`auto`}
            trigger={`mouseenter`}
            onMount={onMount}
            onHide={onHide}
            zIndex={999999}
            render={attrs => (
              <div 
                className={s.tippy}
                data-id={data.type}
                {...attrs}
              >
                <img 
                  className={s.img}
                  src={withpath(`/img/popup/p${data.id}.jpg`)}
                  alt={``}
                />
                <div className={s.tbox}>
                  <div className={s.h0} dangerouslySetInnerHTML={{__html: data.name }}/>
                  <div className={s.h0line} />
                  {
                    data.txt.map((d,i)=>(
                      <div key={'txt'+i} className={s.txtt} dangerouslySetInnerHTML={{__html: d }} />
                    ))
                  }
                </div>
              
              </div>

            )}
          >
            <img 
                className={s.pimg}
                src={withpath(`/img/pointers/p${data.id}.png`)}
                alt={``}
                label={``}
                data-id={data.type}
                dir={sector}
                onLoad={onLoad}
                onKeyDown={()=>{}}
                onMouseEnter={()=>{
                  track(`cta`,`cta`,`hover`,p.data.name);
                }}
                onClick={()=>{ 
                  track(`cta`,`cta`,`click`,p.data.name);
                }}
            />
          </Tippy>

          <div className={s.box} dir={sector}>
            <div className={s.txt}  dir={sector} data-id={p.data.type} >{p.data.name}</div>
          </div>
        </div>

      </Html>

  );
}