import React, { useRef, useEffect, useState } from "react"
import { useFrame, useThree } from "@react-three/fiber"
import { OrbitControls } from "@react-three/drei"
import * as THREE from 'three';

const Orbit = p => {

	const vec = new THREE.Vector3()
	const { camera, gl: { domElement }} = useThree()
	const controls = useRef();
	const step = 0.3
	const [camr, setCamr] = useState(false);

	useEffect(() => {
		controls.current.addEventListener("change", () => {
			if(!p.rotate)
				p.setRotate(true)
		})
	}, [p])

	useEffect(() => {
		setCamr(true)
	}, [p.section])

	useFrame((state) => { 
		if(camr){
			state.camera.position.lerp(vec.set(0, 10, 25), step);

			let cx = state.camera.position.x
			let cy = state.camera.position.y
			let cz = state.camera.position.z

			if( (Math.round(cx, -1) === 0) && (Math.round(cy, -1) === 10) && (Math.round(cz, -1) === 25) ){
				setCamr(false)
			}
			
			// state.camera.lookAt(0, 0, 0)
			state.camera.updateProjectionMatrix()
			controls.current.update();
		}
	});

	const aa = (p.section !== 2) ? Infinity : Math.PI/2
	const pa = (p.section !== 3) ? ((p.size==="XS")?(Math.PI/2 - Math.PI/24):Math.PI/2) : Math.PI/2 - Math.PI/24

	return (
		<OrbitControls
			enabled={(p.section !== 0)}
			ref={controls}
			args={[camera, domElement]}
			enableDamping

			minPolarAngle={0}
			maxPolarAngle={pa}

			minAzimuthAngle={-aa}
			maxAzimuthAngle={aa}

			dampingFactor={0.1}
			enableKeys={false}
			enablePan={false}
			enableZoom={false}
			autoRotateSpeed={2} 
			autoRotate={false}
			{...p}
		/>
	)
}

export default Orbit