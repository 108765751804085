// extracted by mini-css-extract-plugin
export const controls = "controls-module--controls--2NZUM";
export const toppath = "controls-module--toppath--2N79v";
export const centerpath = "controls-module--centerpath--3t7-x";
export const bottompath = "controls-module--bottompath--2KrCr";
export const descbox = "controls-module--descbox--2aIz-";
export const desc2 = "controls-module--desc2--w1FFk";
export const desc2m = "controls-module--desc2m--1zfBI";
export const txtbox = "controls-module--txtbox--3qGg1";
export const txtboxabs = "controls-module--txtboxabs--1kPNU";
export const txt = "controls-module--txt--d3vY3";
export const linkbox = "controls-module--linkbox--1dWWy";
export const desc = "controls-module--desc--2FWQ2";
export const descclick = "controls-module--descclick--xqaiS";
export const descclickm = "controls-module--descclickm--hscH3";
export const link = "controls-module--link--pdBDp";
export const linkactive = "controls-module--linkactive--1Zak9";
export const button = "controls-module--button--2qn59";
export const tippy = "controls-module--tippy--Nu1Ux";
export const tippytxt = "controls-module--tippytxt--3rMef";
export const tip1box = "controls-module--tip1box--2z-Rt";
export const tip2box = "controls-module--tip2box--3_vTm";
export const subbox20 = "controls-module--subbox20--mMqfe";
export const subbox21 = "controls-module--subbox21--3cg7h";
export const tippylist = "controls-module--tippylist--pgG_7";
export const tippybtn = "controls-module--tippybtn--2mfku";
export const rotate = "controls-module--rotate--fvciE";
export const modellinkbox = "controls-module--modellinkbox--1aqT-";
export const line = "controls-module--line--qkGcr";
export const back = "controls-module--back--2yZvb";
export const forw = "controls-module--forw--C04-E";