import React from "react"

import { track } from "../utils/track";

import * as s from "./reading.module.css"

// import ImageFadeIn from "react-image-fade-in";

import Arrow from '../assets/arrow.svg';
import { withpath } from '../utils/index.js'
export default function Reading(p) {

	const data = p.data;

	return (
		<section className={(p.modal===0)?s.reading:s.reading2}>
			<div className={s.h0} dangerouslySetInnerHTML={{__html: (p.modal===0) ? data.h0 : data.h2 }} />
			<div className={s.links}>
			{
				data.articles.map((d,i)=>(
					<div
						key={`key`+i}
						className={s.article}
						data-id={i}
						role = "button"
						tabIndex="0"
						style={{display: ((i+1) === p.modal)? `none` : `block`}}
						onKeyDown={()=>{

						}}
						onClick={()=>{
							p.setModal(1+i);
							track(`cta`,`cta`,`click`, d.h0);
						}}
					>

						<div className={s.imgbox}>
							<img 
								className={s.img}
								src={withpath(`/img/articles/article${i+1}.jpg`)}
								alt={d.h0}
							/>
						</div>

						<div className={s.txt} dangerouslySetInnerHTML={{__html: d.h0 }} />

						<div className={s.read}>
							<div className={s.readtxt} dangerouslySetInnerHTML={{__html: data.h1 }} />
							<Arrow className={s.arrow} />
						</div>

					</div>
				))
			}
			</div>
		</section>
	)
}