import { useState, useEffect } from "react"
const isBrowser = typeof window !== "undefined"


export default function useSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  
  const [windowSize, setWindowSize] = useState(isBrowser?handleResize2:`LG`);

    function handleResize2() {
      // Set window width/height to state
      let nwd = `LG`
      if(window.innerWidth < 576){
        nwd = `XS`
      }else if(window.innerWidth < 992){
        nwd = `MD`
      }
      return nwd;
    }

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state

      let nwd = `LG`
      if(window.innerWidth < 576){
        nwd = `XS`
      }else if(window.innerWidth < 992){
        nwd = `MD`
      }

        setWindowSize(nwd);
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}