// import * as THREE from 'three'

import React, { 
  useRef 
  // useEffect, 
  // useMemo 
} from 'react'

import { 
  // extend, 
  // useThree, 
  useFrame 
} from '@react-three/fiber'

import { 
  EffectComposer, 
  // DepthOfField, 
  Bloom, 
  // SelectiveBloom, 
  // Noise, 
  // Vignette, 
  SSAO, 
  // ToneMapping 
} from '@react-three/postprocessing'

import { 
  // KernelSize, 
  BlendFunction } from 'postprocessing'


// extend({ 
//   EffectComposer, 
//   ShaderPass, 
//   RenderPass, 
//   BloomPass,
//   SSAOPass, 
//   SAOPass, 
//   SMAAPass, 
//   BokehPass, 
//   UnrealBloomPass, 
//   KaleidoShader, 
//   AdaptiveToneMappingPass 
// })

export default function Effects(p) {
  // const composer = useRef()
  // console.log('composer', composer)
  // const { scene, gl, size, camera } = useThree()


  const ref = useRef()
  useFrame((state) => {
    // Disable SSAO on regress
    ref.current.blendMode.setBlendFunction(state.performance.current < 1 ? BlendFunction.SKIP : BlendFunction.MULTIPLY)
  }, [])


  return (
    <EffectComposer>
        {/* <DepthOfField focusDistance={0} focalLength={0.02} bokehScale={2} height={480} /> */}
        {/* <SelectiveBloom /> */}
        {/* <Noise opacity={0.02} /> */}
        {/* <Vignette eskil={false} offset={0.1} darkness={1.1} /> */}
        {/* <ToneMapping/> */}

        <Bloom luminanceThreshold={0.4} luminanceSmoothing={0.95} height={150} intensity={0.7} />
        
       
        <SSAO
          ref={ref} 
          rings={7}
          samples={9} 
          radius={0.04} 
          resolutionScale={1} 
          bias={0.025}
          intensity={1} 
          luminanceInfluence={.1}
          distanceScaling={false}
          rangeThreshold={0.001}
          rangeFalloff={0.1}
          distanceThreshold={0.9}
          distanceFalloff={0.001}
        />
        
      
      </EffectComposer>
  )
}
