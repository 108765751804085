import React, { useEffect, useState } from "react"
import Tippy from '@tippyjs/react/headless';

import { track } from "../utils/track";

import { gsap } from "gsap/dist/gsap";
import Arrow from '../assets/arr.svg';
import Rotate from '../assets/rotate.svg';
import Tip1 from '../assets/tip1.svg';
import Tip2 from '../assets/tip2.svg';

import * as s from "./controls.module.css"
const tip = `.` + s.tippy

const isBrowser = typeof window !== "undefined"
const touch = (isBrowser) ? matchMedia('(hover: none)').matches : false;

export default function Controls(p) {
	const data = p.data;
	// const links = [p.data[1], p.data[2]];
	const refid = ((p.section === 1)) ? 2 : 1;

	useEffect(() => {
		let desc = document.querySelector(`#pictetdescbox`);
		gsap.set(desc, {autoAlpha: 0})

		let btns = document.querySelector(`#modellinkbox`);
		gsap.set(btns, {autoAlpha: 0})

		let legend = document.querySelector(`#legendbox`);
		gsap.set(legend, {autoAlpha: 0})
	},[])

	function onMount() {
	    gsap.killTweensOf(tip);
	    gsap.set(tip, {autoAlpha: 0, transformOrigin:"50% 50%"});
	    gsap.to(tip, {autoAlpha: 1, duration: 0.5, ease: `power2.out`});
	}
	function onHide({ unmount }) {}

	const [visible0, setVisible0] = useState(false);
	const show0 = () => setVisible0(true);
	const hide0 = () => setVisible0(false);

	const [visible1, setVisible1] = useState(false);
	const show1 = () => setVisible1(true);
	const hide1 = () => setVisible1(false);


	return (
		<section className={s.controls}>

			<div className={s.toppath} />
			{ (p.section === 0) && <div className={s.centerpath} /> }
			<div className={s.bottompath} />

			<div className={s.txtbox} id={`pictettxtbox`}>
				<div className={s.txtboxabs}>
				{
					p.data[0].txt.map((d,i)=>(
						<div
							key={`key`+i}
							className={s.txt}
							data-id={i}
							dangerouslySetInnerHTML={{__html: d }}
						/>
					))
				}
				</div>
			</div>

			<div className={s.linkbox + ` ` + ((p.isLoaded)?s.linkactive:``)}>
				
				<div className={s.link} data-id={0}>
					<Tippy
						placement={(p.size==="XS")?`top`:`auto`}
						interactive={touch?true:false}
						visible={visible0} 
						onClickOutside={hide0}
						onMount={onMount}
						onHide={onHide}
						zIndex={999999}
						render={attrs => (
							<div
								className={s.tippy}
								data-id={0} 
								{...attrs}
							>
								{
									data[1].desc.map((q,j)=>(
										<div key={'key'+j} className={s.tippytxt} dangerouslySetInnerHTML={{__html: q }} />
									))
								}
								{
									data[1].list.map((q,j)=>(
										<div key={'key'+j} className={s.tippylist} dangerouslySetInnerHTML={{__html: q }} />
									))
								}
								<div className={s.tip1box} >
									<div className={s.tippytxt} dangerouslySetInnerHTML={{__html: data[1].desc2[0] }} />
									<Tip1 />
								</div>


								<div 
									className={s.tippybtn} 
									dangerouslySetInnerHTML={{__html: `Tap to explore` }} 
									role = "button"
									tabIndex="0"
									aria-label={"Tap to explore"}
									onKeyDown={()=>{}}
									onClick={()=>{
										p.newSection(1);
										hide0()
									}}
								/>

							</div>
						)}
					>
						<div 
							className={s.button} 
							data-id={0}
							role = "button"
							tabIndex="0"
							id={`pictetbtn0`}
							onKeyDown={()=>{}}
							onMouseEnter={()=>{
								track(`cta`,`cta`,`hover`,`Repurposing existing buildings`);
								if(!touch)
									show0()
							}}
							onMouseLeave={()=>{
								if(!touch)
									hide0()
							}}
							onClick={()=>{ 
								if(!touch){
									track(`cta`,`cta`,`click`,`Repurposing existing buildings`);
									p.newSection(1);
									hide0();
								}
								else{
									if(visible0){
										track(`cta`,`cta`,`click`,`Repurposing existing buildings`);
										p.newSection(1);
										hide0();
									}
									else{
										track(`cta`,`cta`,`hover`,`Repurposing existing buildings`);
										show0()
									}
								}
							}}
						>
							<span dangerouslySetInnerHTML={{__html: p.data[1].h0 }} />
						</div>
					</Tippy>
				</div>

				<div className={s.link} data-id={1}>
					<Tippy
						placement={(p.size==="XS")?`top`:`auto`}
						interactive={touch?true:false}
						visible={visible1} 
						onClickOutside={hide1}
						onMount={onMount}
						onHide={onHide}
						zIndex={999999}
						render={attrs => (
							<div
								className={s.tippy}
								data-id={1} 
								{...attrs}
							>
								{
									data[2].desc.map((q,j)=>(
										<div key={'key'+j} className={s.tippytxt} dangerouslySetInnerHTML={{__html: q }} />
									))
								}
								{
									data[2].list.map((q,j)=>(
										<div key={'key'+j} className={s.tippylist} dangerouslySetInnerHTML={{__html: q }} />
									))
								}

								<div className={s.tip2box} >
									<div className={s.subbox20}>
										{
											data[2].desc2.map((q,j)=>(
												<div key={'key'+j} className={s.tippytxt} dangerouslySetInnerHTML={{__html: q }} />
											))
										}
									</div>
									<div className={s.subbox21}>
										<Tip2 />
									</div>
								</div>


								<div 
									className={s.tippybtn} 
									dangerouslySetInnerHTML={{__html: `Tap to explore` }} 
									role = "button"
									tabIndex="0"
									aria-label={"Tap to explore"}
									onKeyDown={()=>{}}
									onClick={()=>{
										p.newSection(2);
										hide1()
									}}
								/>

							</div>
						)}
					>
						<div 
							className={s.button} 
							data-id={1}
							role = "button"
							tabIndex="0"
							id={`pictetbtn1`}
							onKeyDown={()=>{}}
							onMouseEnter={()=>{
								track(`cta`,`cta`,`hover`,`Sustainable construction`);
								if(!touch)
									show1()
							}}
							onMouseLeave={()=>{
								if(!touch)
									hide1()
							}}
							onClick={()=>{ 
								if(!touch){
									track(`cta`,`cta`,`click`,`Sustainable construction`);
									p.newSection(2);
									hide1();
								}
								else{
									if(visible1){
										track(`cta`,`cta`,`click`,`Sustainable construction`);
										p.newSection(2);
										hide1();
									}else{
										track(`cta`,`cta`,`hover`,`Sustainable construction`);
										show1()
									}
								}
							}}
						>
							<span dangerouslySetInnerHTML={{__html: p.data[2].h0 }} />
						</div>
					</Tippy>
				</div>

				<div className={s.desc} id={`pictetdesc`} >
					<div dangerouslySetInnerHTML={{__html: data[0].desc }} />
					<div className={s.descclick} dangerouslySetInnerHTML={{__html: `Hover and then click to explore` }} />
					<div className={s.descclickm} dangerouslySetInnerHTML={{__html: `Tap to explore` }} />
				</div>
			</div>

			<div className={s.modellinkbox} id={`modellinkbox`}>
				<div 
					className={s.back}
					role = "button"
					tabIndex="0"
					onKeyDown={()=>{}}
					onClick={()=>{
						track(`cta`,`cta`,`click`,`Back to main page`);
						p.newSection(0);
					}}
				>
					<Arrow />
				</div>

				<div 
					className={s.forw}
					role = "button"
					tabIndex="0"
					data-id={p.section}
					onKeyDown={()=>{

					}}
					onClick={()=>{
						track(`cta`,`cta`,`click`,data[refid].h0);
						p.newSection(refid);
					}}
				>
					<span dangerouslySetInnerHTML={{__html: data[refid].h0 }} />
				</div>
			</div>

			<div className={s.descbox} id={`pictetdescbox`}>
				<div
					className={s.desc2}
					dangerouslySetInnerHTML={{__html: `Rotate & hover over the image touch points to explore` }}
				/>
				<div
					className={s.desc2m}
					dangerouslySetInnerHTML={{__html: `Rotate & tap the image touch points to explore` }}
				/>
			</div>

			<div className={s.rotate} id={`rotatebox`}>
				<div style={{opacity: (p.rotate?0:1)}}>
				<Rotate />
				</div>
			</div>

		</section>
	)
}